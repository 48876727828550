import AllerHoroscopes from '@allermedia/horoscopes-api';

export const useZodiacs = () => {
  // Instantiate Horoscopes API class, should ensure that Zodiac type is known.
  const allerHoroscopes = new AllerHoroscopes();

  /**
   * Get an array of danish translated weekdays.
   *
   * @returns string[]
   */
  const danishWeekdays = ['søndag', 'mandag', 'tirsdag', 'onsdag', 'torsdag', 'fredag', 'lørdag'];

  /**
   * Get an array of danish translated months.
   *
   * @returns string[]
   */
  const danishMonths = ['januar', 'februar', 'marts', 'april', 'maj', 'juni', 'juli', 'august', 'september', 'oktober', 'november', 'december'];

  /**
   * Get an array of danish translated months in short.
   *
   * @returns string[]
   */
  const danishMonthsShort = ['jan', 'feb', 'mar', 'apr', 'maj', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

  /**
   * Get an object mapping number to zodiac urls.
   *
   * @returns Aller.ZodiacMapping
   */
  const zodiacUrlMapping: Aller.ZodiacMapping = {
    1: 'vandmand',
    2: 'vaedder',
    3: 'krebs',
    4: 'stenbuk',
    5: 'tvilling',
    6: 'loeve',
    7: 'vaegt',
    8: 'fisk',
    9: 'skytte',
    10: 'skorpion',
    11: 'tyr',
    12: 'jomfru',
  };

  const zodiacUrlMappingLong: Aller.ZodiacMapping = {
    1: 'vandmanden',
    2: 'vaedderen',
    3: 'krebsen',
    4: 'stenbukken',
    5: 'tvilling',
    6: 'loeven',
    7: 'vaegten',
    8: 'fisken',
    9: 'skytten',
    10: 'skorpionen',
    11: 'tyren',
    12: 'jomfruen',
  };

  const zodiacNameMapping: Aller.ZodiacMapping = {
    1: 'Vandmanden',
    2: 'Vædderen',
    3: 'Krebsen',
    4: 'Stenbukken',
    5: 'Tvillingen',
    6: 'Løven',
    7: 'Vægten',
    8: 'Fisken',
    9: 'Skytten',
    10: 'Skorpionen',
    11: 'Tyren',
    12: 'Jomfruen',
  };

  /**
   * Get a readable datespan for the zodiac.
   *
   * @param zodiac
   *
   * @returns string
   */
  const zodiacDateSpan = (zodiac: Zodiac): string => {
    const from_date = new Date(zodiac.from_date);
    const to_date = new Date(zodiac.to_date);
    return from_date.getDate() + '. ' + danishMonths[from_date.getMonth()] + ' - ' + to_date.getDate() + '. ' + danishMonths[to_date.getMonth()];
  };

  /**
   * Get a readable datespan for the zodiac.
   *
   * @param zodiac
   *
   * @returns string
   */
  const zodiacDateSpanShort = (zodiac: Zodiac): string => {
    const from_date = new Date(zodiac.from_date);
    const to_date = new Date(zodiac.to_date);
    return from_date.getDate() + '. ' + danishMonthsShort[from_date.getMonth()] + '. - ' + to_date.getDate() + '. ' + danishMonthsShort[to_date.getMonth()] + '.';
  };

  return {
    danishWeekdays,
    danishMonths,
    zodiacDateSpan,
    zodiacDateSpanShort,
    zodiacUrlMapping,
    zodiacUrlMappingLong,
    zodiacNameMapping,
  };
};
