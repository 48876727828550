<template>
  <section class="bg-blue-400 -mx-10 md:mx-0 mt-10 pb-20 mb-20" v-if="sortedZodiacs.length" data-aller-id="horoscopeslider">
    <SnapSlider arrows-top="mt-8" :slider-conf="{
      items: 4,
      stagePadding: '.5',
      responsive: [
        {
          breakpoint: 768,
          items: 8,
        },
      ]
    }" :items="sortedZodiacs" arrow-class="disabled:bg-svg-arrow-gray-100 bg-svg-arrow-white hover:bg-svg-arrow-red-400">
      <template #sliderHeader>
        <Bubble title="Læs dit horoskop her" url="/horoskoper" color="bg-pink-400" class="-mt-10 ml-5 mb-5 md:mb-15" />
      </template>
      <template #[index] v-for="(zodiac, index) in sortedZodiacs" :key="zodiac.name">
        <article class="px-10 h-full" :data-aller-id="`horoscopeslider-${zodiac.name}`">
          <NuxtLink :to="'/horoskoper/stjernetegn/' + zodiacUrlMapping[zodiac.id]" class="h-full !flex flex-col justify-center items-center relative group !text-white hover:text-red-400">
            <div class="flex-grow w-full">
              <img :src="`/svg/horoskoper/${zodiac.name.toLowerCase()}.svg`" width="60" height="60" :alt="`Horoskop ${zodiac.name}`" :title="`Se horoskop for ${zodiac.name}`" :loading="lazyImg" />
            </div>
            <h3 class="!font-normal text-16 md:text-18 leading-24 underline mt-3 md:mt-0">{{ zodiac.title }}</h3>
          </NuxtLink>
        </article>
      </template>
    </SnapSlider>
  </section>
</template>

<script setup lang="ts">
import AllerHoroscopes from '@allermedia/horoscopes-api';

// Instantiate Horoscopes API class.
const allerHoroscopes = new AllerHoroscopes();

defineProps({
  lazyImg: {
    type: String,
    default: 'eager'
  }
});

const { zodiacUrlMapping } = useZodiacs();

/**
 * This method calculates which zodiac is the current sign as that is to be shown first
 */
const currentSign = () => {
  let date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;

  switch (month) {
    case 1:
      if (day < 20)
        return 'Capricorn';
      else
        return 'Aquarius';
    case 2:
      if (day < 19)
        return 'Aquarius';
      else
        return 'Pisces';
    case 3:
      if (day < 21)
        return 'Pisces';
      else
        return 'Aries';
    case 4:
      if (day < 20)
        return 'Aries';
      else
        return 'Taurus';
    case 5:
      if (day < 21)
        return 'Taurus';
      else
        return 'Gemini';
    case 6:
      if (day < 21)
        return 'Gemini';
      else
        return 'Cancer';
    case 7:
      if (day < 23)
        return 'Cancer';
      else
        return 'Leo';
    case 8:
      if (day < 23)
        return 'Leo';
      else
        return 'Virgo';
    case 9:
      if (day < 23)
        return 'Virgo';
      else
        return 'Libra';
    case 10:
      if (day < 23)
        return 'Libra';
      else
        return 'scorpio';
    case 11:
      if (day < 22)
        return 'Scorpio';
      else
        return 'Sagittarius';
    case 12:
      if (day < 22)
        return 'Sagittarius';
      else
        return 'Capricorn';
    default:
      return '';
  }
}

/**
 * Get the zodiacs.
 *
 * @type Zodiac[]
 */
const sortedZodiacs = ref<Zodiac[]>([]);
try {
  const apiZodiacs = await allerHoroscopes.zodiacs.all();
  const current = apiZodiacs.map(zodiac => zodiac.name).indexOf(currentSign());
  const splice = apiZodiacs.splice(0, current ? current : 0);
  sortedZodiacs.value = apiZodiacs.concat(splice);
} catch (e) {
  // Horoscopes could not be loaded.
}
</script>
